import React from 'react';


const Cabecera = () => {


    return (
        <div className='cabecera'>
                {/* <img src="..\src\img\jc-logo.png" alt="" /> */}
                <h1>FUERTES E HIJOS</h1>         
        </div>
    );
}

export default Cabecera;
