import React from 'react'
import Cabecera from '../components/Cabecera';
import PiePagina from '../components/PiePagina';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ReplyIcon from '@mui/icons-material/Reply';
import HomeIcon from '@mui/icons-material/Home';

export const DetallesAPI = () => {

    const Navigate = useNavigate();


    return (
        <div>
            <Cabecera></Cabecera>

            <div className='flex-vertical' style={{ marginTop: '11em', backgroundColor: '#FFF', borderRadius: '15px', padding: '2em 3.5em', minWidth: '0em !important', boxShadow: 'rgba(0, 0, 0, 0.15) 4px 5px 20px 0px' }}>
                <div className='flex-vertical' style={{ gap: '2em', padding: '1em 0', minWidth: '100%' }}>
                    <div className='pedido' style={{ gap: '2em', textAlign: 'center', minWidth: '100%' }}>
                        <Button variant="text" color='secondary' style={{ fontSize: '18px', fontWeight: 'bold', color: '#72bd9a' }} onClick={() => Navigate("/")}><ReplyIcon className='icono' /> Atras</Button>
                        <h2>Detalles API</h2>
                        <Button variant="text" color='secondary' style={{ fontSize: '18px', fontWeight: 'bold', color: '#72bd9a' }} onClick={() => Navigate("/")}><HomeIcon className='icono' /> Inicio</Button>
                    </div>
                    <div className='flex-vertical' style={{ gap: '1em', textAlign: 'left', minWidth: '100%' }}>
                        <strong>API SECRET KEY</strong>
                        <TextField value="90994955f99d2a0aac1913e2" name={"nSemana"} label="API SECRET KEY" id="custom-css-outlined-input" type="text" style={{minWidth: '100%'}}/>
                        <strong>API PUBLIC KEY</strong>
                        <TextField value="6400d5e699883f53554d20a0" name={"nSemana"} label="API PUBLIC KEY" id="custom-css-outlined-input" type="text" style={{minWidth: '100%'}}/>
                    </div>


                </div>

            </div>

            <PiePagina></PiePagina>
        </div>
    )
}
