import React, { useState, useEffect } from 'react'
import Cabecera from '../components/Cabecera';
import LoginForm from '../components/LoginForm';
import MainMenu from '../components/MainMenu';
import PiePagina from '../components/PiePagina';

export const MainView = () => {

    return (
        <div className='main'>
            <Cabecera></Cabecera>
            <MainMenu></MainMenu>
            <PiePagina></PiePagina>
        </div>
    )
}

export default MainView;

