import React, { useEffect, useState } from 'react';
import Cabecera from '../components/Cabecera';
import PiePagina from '../components/PiePagina';
import { BtnNav } from '../components/BtnNav';
import axios from 'axios';
import Button from '@mui/material/Button';
import ReplyIcon from '@mui/icons-material/Reply';
import HomeIcon from '@mui/icons-material/Home';

import { useParams, useNavigate } from 'react-router-dom';


export const DetallesHistorial = () => {

    const Navigate = useNavigate();
    const wsUrl = "www.jcinformatics.com";
    const param = useParams();
    const [pedidos, setPedidos] = useState([]);
    const [pedidosFormat, setPedidosFormat] = useState([])
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        firstLoad();
        // console.log("clientess", clientes)
    }, [])

    const CrearDetalles = (data) => {
        let temp = [];

        data.forEach((dato) => {
            let clienteExistenteIndex = temp.findIndex(item => item.cliente === dato.cliente);

            if (clienteExistenteIndex !== -1) {
                // Si el cliente ya existe en temp, agrega el dato al array 'dia'
                temp[clienteExistenteIndex].dia.push(dato);
            } else {
                // Si el cliente no existe en temp, crea un nuevo objeto con el cliente y un array 'dia'
                temp.push({ "cliente": dato.cliente, "dia": [dato] });
            }
        });
        // console.log("Temp: ", temp);
        setPedidosFormat(temp);
    };
    const firstLoad = () => {

        //cargar pedido
        axios.get('https://' + wsUrl + '/fuertes/WS/verDetallesPedido.php?semana=' + param.semana)
            .then(function (response) {
                setPedidos(response.data);
                // setPedidosFormat(response.data);
                setLoaded(true);
                // CrearDetalles(response.data);
                // console.log("Array: ", response.data);
            })
            .catch(function (error) {
                console.error('Error en la solicitud:', error);
            })
            .finally(function () {
            })
    }

    return (
        <div className='flex-vertical' style={{ padding: '0em 0em' }}>
            <Cabecera></Cabecera>
            <div className='flex-vertical' style={{ marginTop: '7em', marginBottom: '7em', backgroundColor: '#FFF', borderRadius: '15px', padding: '2em 3.5em', minWidth: '0em !important', boxShadow: 'rgba(0, 0, 0, 0.15) 4px 5px 20px 0px' }}>
                {(loaded === true) && (
                    <>
                        <div className='pedido' style={{ textAlign: 'center', gap: '1em' }}>
                            <Button variant="text" color='secondary' style={{ fontSize: '18px', fontWeight: 'bold', color: '#72bd9a' }} onClick={() => Navigate("/Historial")}><ReplyIcon className='icono' /> Atras</Button>
                            <h4 style={{ margin: '0em, 1em' }}>Detalles pedido semana:&nbsp;{param.semana} </h4>
                            <Button variant="text" color='secondary' style={{ fontSize: '18px', fontWeight: 'bold', color: '#72bd9a' }} onClick={() => Navigate("/")}><HomeIcon className='icono' /> Inicio</Button>
                        </div>


                        {pedidos.map((pedido, index) => (
                            <div key={(index)} className='flex-vertical' style={{marginBottom: '0.5em'}}>
                                <h4 style={{ marginBottom: '0em' }}>Cliente: {pedido.nombre}</h4>
                                <div className='flex-horizontal' style={{ gap: '1em', fontSize: '0.8em' }}>
                                    {(pedido.dias).map((diaInfo, index) => (
                                        <div key={index} className='flex-vertical' style={{ maxWidth: '100%', minWidth: '40%' }}>
                                            <h4>Dia: {diaInfo.dia}</h4>
                                            <small style={{ fontSize: '14px' }}>C. Pequeños: {diaInfo.pequeno}</small>
                                            <small style={{ fontSize: '14px' }}>C. Medianos: {diaInfo.mediano}</small>
                                            <small style={{ fontSize: '14px' }}>C. Grandes: {diaInfo.grande}</small>
                                            <small style={{ fontSize: '14px' }}>Lechales: {diaInfo.lechal}</small>
                                            <small style={{ fontSize: '14px' }}>Cabritos: {diaInfo.cabrito}</small>
                                            <small style={{ fontSize: '14px' }}>Sangre: {diaInfo.sangre} kg</small>
                                        </div>
                                    ))}
                                </div>
                                <hr style={{ width: '100%', marginTop: '1.5em'}}/>
                            </div>
                        ))}
                    </>
                )}
            </div>
            <PiePagina></PiePagina>
        </div>

    )
}

export default DetallesHistorial;
