import React, { useEffect, useState } from 'react';
import Cabecera from '../components/Cabecera';
import PiePagina from '../components/PiePagina';
import axios from 'axios';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import HomeIcon from '@mui/icons-material/Home';



export const DetallesCli = () => {

  const Navigate = useNavigate();

  const wsUrl = "www.jcinformatics.com";

  const [clientes, setClientes] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [numTlf, setNumTlf] = useState("");


  useEffect(() => {
    firstLoad();
  }, [])

  const firstLoad = () => {

    //cargar compras
    axios.get('https://' + wsUrl + '/fuertes/WS/clientes.php')
      .then(function (response) {
        // console.log(response.data);
        setClientes(response.data);
        setLoaded(true);
      })
      .catch(function (error) {
        console.error('Error en la solicitud:', error);
      })
      .finally(function () {
      })
  }
  const handleCallClick = (numero) => {
    setNumTlf(numero);
    // console.log(numero);
    window.location.href = `tel:${numTlf}`;
  };

  return (
    <div className='flex-vertical' style={{ padding: '1em 1em' }}>
      <Cabecera></Cabecera>
      <div className='flex-vertical' style={{ marginTop: '6em', marginBottom: '7em', backgroundColor: '#FFF', borderRadius: '15px', padding: '1em 1em', minWidth: '0em !important', boxShadow: 'rgba(0, 0, 0, 0.15) 4px 5px 20px 0px' }}>
        <div className='pedido' style={{ textAlign: 'center' }}>
          <Button variant="text" color='secondary' style={{ fontSize: '18px', fontWeight: 'bold', color: '#72bd9a' }} onClick={() => Navigate("/")}><ReplyIcon className='icono' />Atras</Button>
          <h3>Detalles clientes</h3>
          <Button variant="text" color='secondary' style={{ fontSize: '18px', fontWeight: 'bold', color: '#72bd9a' }} onClick={() => Navigate("/")}><HomeIcon className='icono' />Inicio</Button>
        </div>

        {(loaded === true) && (
          <>
            {clientes.map((cliente, index) => (
              <div>
                <div>
                </div>
                <div key={index} className='flex-vertical' style={{ gap: '0em' }}>
                  <h4>{cliente.nombre}</h4>
                  <p>Nº Tlf: <a href={`tel:${cliente.tlf}`} style={{ textDecoration: 'none', color: '#72bd9a', fontWeight: 'bold' }} onClick={() => handleCallClick(cliente.tlf)}>{cliente.tlf}</a></p>
                  <hr style={{ width: '100%' }} />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <PiePagina></PiePagina>
    </div>

  )
}

export default DetallesCli;
