import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { alpha, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2'


const LoginForm = () => {
    //global
    const Navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    //util
    const wsUrl = "www.jcinformatics.com";
    // const [showPassword, setShowPassword] = useState(false);

    //value
    // const handleClickShowPassword = () => setShowPassword((show) => !show);

    // const handleMouseDownPassword = (event) => {
    //     event.preventDefault();
    // };

    const handleLogin = async () => {
        console.log(username);
        console.log(password);
        try {
            const response = await axios.post('https://' + wsUrl + '/fuertes/WS/login.php', { username: username, password: password });

            if (response.data.success) {
                // Guarda el token en el almacenamiento local o de sesión
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('code', response.data.CodReferencia);
                localStorage.setItem('username', response.data.nombre);
                localStorage.setItem('mail', response.data.mail);
                localStorage.setItem('DesRepresentante', response.data.DesRepresentante);


                Swal.fire({
                    icon: "success",
                    title: "Bienvenido/a \n" + username,
                    showConfirmButton: false,
                    timer: 1500
                });
                Navigate('/');

            } else {
                console.log(response.data)
                Swal.fire({
                    icon: "error",
                    title: "Error de inicio de sesión: \n" + response.data.message,
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        } catch (error) {
            console.error('Error de red:', error.message);
        }
    };

    // const CssTextField = styled(TextField)({
    //     '& label.Mui-focused': {
    //         color: '#72bd9a',
    //     },
    //     '& .MuiInput-underline:after': {
    //         borderBottomColor: '#B2BAC2',
    //     },
    //     '& .MuiOutlinedInput-root': {
    //         '& fieldset': {
    //             borderColor: '#E0E3E7',
    //         },
    //         '&:hover fieldset': {
    //             borderColor: '#B2BAC2',
    //         },
    //         '&.Mui-focused fieldset': {
    //             borderColor: '#72bd9a',
    //         },
    //     }
    // });
    const handleChange = (event) => {
        switch (event.target.name) {
            case 'username':
                setUsername(event.target.value);
                break;

            case 'password':
                setPassword(event.target.value);
                break;

            default:
                break;
        }

    }

    return (
        <div className='flex-vertical' style={{ marginTop: '11em', backgroundColor: '#FFF', borderRadius: '15px', padding: '2em 3.5em', minWidth: '0em !important', boxShadow: 'rgba(0, 0, 0, 0.15) 4px 5px 20px 0px' }}>
            <div className='flex-vertical' style={{ gap: '1em' }}>
                <h2>Inicia sesión</h2>
                <TextField
                    name='username'
                    value={username}
                    id="standard-basic"
                    label="Nombre usuario"
                    variant="outlined"
                    color="secondary"
                    onChange={handleChange}
                />
                <TextField
                    name='password'
                    value={password}
                    id="standard-password-input"
                    label="Contraseña"
                    type="password"
                    autoComplete="current-password"
                    variant="outlined"
                    color="secondary"
                    onChange={handleChange}
                />
                <Button variant="contained" color='secondary' style={{ backgroundColor: '#72bd9a', marginTop: '1em' }} onClick={handleLogin}>Iniciar sesión</Button>

            </div>
        </div>
    );
}

export default LoginForm;
