import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { MainView } from '../pages/MainView';
import MainForm from '../pages/MainForm';
import { Historial } from '../pages/Historial';
import { DetallesCli } from '../pages/DetallesCli';
import { Login } from '../pages/Login';
import { DetallesHistorial } from '../pages/DetallesHistorial'
import { EditarPedido } from '../pages/EditarPedido'
import PrivateRoute from './PrivateRoute';
import axios from 'axios';
import { DetallesAPI } from '../pages/DetallesAPI';




const Router = () => {

	//global
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');	

	return (
		<div className='back-main'>
			<BrowserRouter>
				<Routes>
					<Route element={<PrivateRoute />}>
						<Route path="/" element={<MainView />}></Route>
						<Route path="/MainForm" element={<MainForm />}></Route>
						<Route path="/Historial" element={<Historial />}></Route>
						<Route path="/DetallesClientes" element={<DetallesCli />}></Route>
						<Route path="/DetallesAPI" element={<DetallesAPI />}></Route>
						<Route path="/EditarPedido/:semana" element={<EditarPedido />}></Route>
						<Route path="/DetallesHistorial/:semana" element={<DetallesHistorial />}></Route>
						<Route path="*" element={<Navigate to="/" replace />} />
					</Route>
					<Route path="/Login" element={<Login username={username} setUsername={setUsername} password={password} setPassword={setPassword} />}></Route>
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>


			</BrowserRouter>
		</div >
	)
}

export default Router