import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { styled } from '@mui/material/styles';

export const EditarForm = ({ index, pedidoDetalles, semana, dia }) => {


    const enviarPedido = (pedido) => {
        axios.put('https://' + wsUrl + '/fuertes/WS/crearPedido.php', pedido)
            .then(function (response) {
                // console.log('Despues de enviar',pedido);
                if (response.data.success) {
                    // console.log('Respuesta PHP',response.data);
                    // setColorFondo(true);
                    cambiarColorFondo(index);
                } else {
                    // console.log(response.data);
                }

            })
            .catch(function (error) {
                console.error('Error al enviar la solicitud:', error);
            });
    }

    // const CssTextField = styled(TextField)({
    //     '& label.Mui-focused': {
    //         color: '#72bd9a',
    //     },
    //     '& .MuiInput-underline:after': {
    //         borderBottomColor: '#B2BAC2',
    //     },
    //     '& .MuiOutlinedInput-root': {
    //         '& fieldset': {
    //             borderColor: '#E0E3E7',
    //         },
    //         '&:hover fieldset': {
    //             borderColor: '#B2BAC2',
    //         },
    //         '&.Mui-focused fieldset': {
    //             borderColor: '#72bd9a',
    //         },
    //     }
    // });

    const wsUrl = "www.jcinformatics.com";
    const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
    const [fechaLunes, setFechaLunes] = useState('');
    const [clientes, setClientes] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [divSeleccionado, setDivSeleccionado] = useState(null);
    const [colorFondo, setColorFondo] = useState(false);
    const [semanaPedido, setSemanaPedido] = useState(semana);

    const [valorPequeño1, setValorPequeño1] = useState(pedidoDetalles.dias[0].pequeno);
    const [valorMediano1, setValorMediano1] = useState(pedidoDetalles.dias[0].mediano);
    const [valorGrande1, setValorGrande1] = useState(pedidoDetalles.dias[0].grande);
    const [valorLechal1, setValorLechal1] = useState(pedidoDetalles.dias[0].lechal);
    const [valorCabrito1, setValorCabrito1] = useState(pedidoDetalles.dias[0].cabrito);
    const [valorSangre1, setValorSangre1] = useState(pedidoDetalles.dias[0].sangre);

    const [valorPequeño2, setValorPequeño2] = useState(pedidoDetalles.dias[1].pequeno);
    const [valorMediano2, setValorMediano2] = useState(pedidoDetalles.dias[1].mediano);
    const [valorGrande2, setValorGrande2] = useState(pedidoDetalles.dias[1].grande);
    const [valorLechal2, setValorLechal2] = useState(pedidoDetalles.dias[1].lechal);
    const [valorCabrito2, setValorCabrito2] = useState(pedidoDetalles.dias[1].cabrito);
    const [valorSangre2, setValorSangre2] = useState(pedidoDetalles.dias[1].sangre);

    const [valorPequeño3, setValorPequeño3] = useState(pedidoDetalles.dias[2].pequeno);
    const [valorMediano3, setValorMediano3] = useState(pedidoDetalles.dias[2].mediano);
    const [valorGrande3, setValorGrande3] = useState(pedidoDetalles.dias[2].grande);
    const [valorLechal3, setValorLechal3] = useState(pedidoDetalles.dias[2].lechal);
    const [valorCabrito3, setValorCabrito3] = useState(pedidoDetalles.dias[2].cabrito);
    const [valorSangre3, setValorSangre3] = useState(pedidoDetalles.dias[2].sangre);

    const [dia1Enviado, setDia1Enviado] = useState(pedidoDetalles.dias[0].envioManual);
    const [dia2Enviado, setDia2Enviado] = useState(pedidoDetalles.dias[1].envioManual);
    const [dia3Enviado, setDia3Enviado] = useState(pedidoDetalles.dias[2].envioManual);




    const cambiarColorFondo = (index) => {
        setDivSeleccionado(index);
    };
    const mostrarForm = (cliente) => {
        setClienteSeleccionado(clienteSeleccionado === cliente ? null : cliente);
    }

    useEffect(() => {
        const lunesDeEstaSemana = obtenerLunesDeEstaSemana();
        // console.log(semana);
        setPedido({ ...pedido, idCliente: pedidoDetalles.idCliente, fecha: lunesDeEstaSemana, semana: pedidoDetalles.semana });

        setPedido({ ...pedido, semana: semanaPedido });
        firstLoad();

    }, []);
    // Obtener la fecha del lunes y establecerla en el estado
    const obtenerLunesDeEstaSemana = () => {
        const today = new Date();
        const dayOfWeek = today.getDay();
        const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust when day is Sunday
        const lunesDeEstaSemana = new Date(today.setDate(diff));
        const formattedLunes = lunesDeEstaSemana.toISOString().split('T')[0];
        return formattedLunes;
    };



    const firstLoad = () => {
        axios.get('https://' + wsUrl + '/fuertes/WS/clientes.php')
            .then(function (response) {
                // console.log(response.data);
                setClientes(response.data);
                setLoaded(true);
            })
            .catch(function (error) {
                console.error('Error en la solicitud:', error);
            })
            .finally(function () {
            })
    }
    const handleChange = (event, dia) => {
        let numDia = event.target.name.charAt(0);
        switch (event.target.name) {
            // case 'nSemana':
            //     console.log(event.target.value);
            //     setSemana(event.target.value);
            //     break;
            //--------------DIA-1-
            case '0pequeño':
                setValorPequeño1(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], pequeño: event.target.value } } });
                break;
            case '0mediano':
                setValorMediano1(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], mediano: event.target.value } } });
                break;
            case '0grande':
                setValorGrande1(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], grande: event.target.value } } });
                break;
            case '0lechal':
                setValorLechal1(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], lechal: event.target.value } } });
                break;
            case '0cabrito':
                setValorCabrito1(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], cabrito: event.target.value } } });
                break;
            case '0sangre':
                setValorSangre1(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], sangre: event.target.value } } });
                break;
            //--------------DIA-2-
            case '1pequeño':
                setValorPequeño2(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], pequeño: event.target.value } } });
                break;
            case '1mediano':
                setValorMediano2(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], mediano: event.target.value } } });
                break;
            case '1grande':
                setValorGrande2(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], grande: event.target.value } } });
                break;
            case '1lechal':
                setValorLechal2(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], lechal: event.target.value } } });
                break;
            case '1cabrito':
                setValorCabrito2(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], cabrito: event.target.value } } });
                break;
            case '1sangre':
                setValorSangre2(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], sangre: event.target.value } } });
                break;
            //--------------DIA-3-
            case '2pequeño':
                setValorPequeño3(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], pequeño: event.target.value } } });
                break;
            case '2mediano':
                setValorMediano3(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], mediano: event.target.value } } });
                break;
            case '2grande':
                setValorGrande3(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], grande: event.target.value } } });
                break;
            case '2lechal':
                setValorLechal3(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], lechal: event.target.value } } });
                break;
            case '2cabrito':
                setValorCabrito3(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], cabrito: event.target.value } } });
                break;
            case '2sangre':
                setValorSangre3(event.target.value);
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], sangre: event.target.value } } });
                break;
            default:
                break;
        }

        // if (event.target.name === 'idCliente') {
        //     setPedido({ ...pedido, idCliente: event.target.value, fecha: fechaLunes});
        // }
    };
    const [pedido, setPedido] = useState({
        "idCliente": pedidoDetalles.idCliente,
        "fecha": pedidoDetalles.fecha,
        "semana": null,
        "dia": [{

            "dia": 1,
            "pequeño": valorPequeño1,
            "mediano": valorMediano1,
            "grande": valorGrande1,
            "lechal": valorLechal1,
            "cabrito": valorCabrito1,
            "sangre": valorSangre1,
        },
        {
            "dia": 2,
            "pequeño": valorPequeño2,
            "mediano": valorMediano2,
            "grande": valorGrande2,
            "lechal": valorLechal2,
            "cabrito": valorCabrito2,
            "sangre": valorSangre2,
        },
        {
            "dia": 3,
            "pequeño": valorPequeño3,
            "mediano": valorMediano3,
            "grande": valorGrande3,
            "lechal": valorLechal3,
            "cabrito": valorCabrito3,
            "sangre": valorSangre3,
        }]
    });

    return (
        <div className={`flex-vertical 
        ${colorFondo ? 'enviado' : 'no-enviado'}`}
            style={{ minWidth: '100%', maxWidth: '100%' }}>

            <div key={index} className={'flex-vertical'} style={{ minWidth: '95%', maxWidth: '95%', padding: '0em 0.5em' }}>
                <div className='flex-horizontal' style={{ justifyContent: 'space-between' }}>
                    <h3>{pedidoDetalles.nombre}</h3>
                    <Button style={{ backgroundColor: '#72bd9a' }} color="secondary" variant="contained" onClick={() => mostrarForm(pedidoDetalles.idCliente)} >
                        {clienteSeleccionado === pedidoDetalles.idCliente ? "Cerrar" : "Abrir"}
                    </Button>
                </div>
                {(clienteSeleccionado === pedidoDetalles.idCliente) && (
                    <div className='flex-vertical' style={{ minWidth: '100%', maxWidth: '100%' }}>
                        <div className='flex-horizontal' style={{ marginTop: '-1em' }}>
                            <div className='flex-horizontal' style={{ minWidth: '10%', gap: '1em' }}>
                                <div className='flex-vertical' style={{ gap: '1em' }}>
                                    <h4>Dia 1</h4>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorPequeño1} name={"0pequeño"} label="C. Pequeño" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorMediano1} name={"0mediano"} label="C. Mediano" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorGrande1} name={"0grande"} label="C. Grande" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorLechal1} name={"0lechal"} label="Lechal" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorCabrito1} name={"0cabrito"} label="Cabrito" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorSangre1} name={"0sangre"} label="Sangre" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                </div>

                                <div className='flex-vertical' style={{ gap: '1em' }}>
                                    <h4>Dia 2</h4>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorPequeño2} name={"1pequeño"} label="C. Pequeño" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorMediano2} name={"1mediano"} label="C. Mediano" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorGrande2} name={"1grande"} label="C. Grande" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorLechal2} name={"1lechal"} label="Lechal" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorCabrito2} name={"1cabrito"} label="Cabrito" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorSangre2} name={"1sangre"} label="Sangre" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                </div>

                                <div className='flex-vertical' style={{ gap: '1em' }}>
                                    <h4>Dia 3</h4>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorPequeño3} name={"2pequeño"} label="C. Pequeño" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorMediano3} name={"2mediano"} label="C. Mediano" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorGrande3} name={"2grande"} label="C. Grande" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorLechal3} name={"2lechal"} label="Lechal" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorCabrito3} name={"2cabrito"} label="Cabrito" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                    <div className='flex-horizontal'>
                                        <TextField value={valorSangre3} name={"2sangre"} label="Sangre" id="custom-css-outlined-input" type="number" step="0.5" onChange={handleChange} InputLabelProps={{ shrink: true, }}
                                        // disabled={index === divSeleccionado ? true : false} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button style={{ minWidth: '10em', marginTop: '2em', marginBottom: '2em', backgroundColor: '#72bd9a' }} color="secondary" variant="contained" onClick={() =>{ enviarPedido(pedido); setColorFondo(true);}}
                        // disabled={index === divSeleccionado ? true : false}
                        >
                            Editar pedido</Button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default EditarForm;
