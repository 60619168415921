import React, { useEffect, useState } from 'react';
import Cabecera from '../components/Cabecera';
import PiePagina from '../components/PiePagina';
import EditarForm from '../components/EditarForm';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { alpha, styled } from '@mui/material/styles';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import HomeIcon from '@mui/icons-material/Home';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';




export const EditarPedido = () => {

    const Navigate = useNavigate();
    const wsUrl = "www.jcinformatics.com";
    const param = useParams();
    const [clientes, setClientes] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
    const [colorFondo, setColorFondo] = useState(false);
    const [divSeleccionado, setDivSeleccionado] = useState(null);
    const [pedidos, setPedidos] = useState([]);
    const [dia, setDia] = useState(1);
    const [enviado, setEnviado] = useState(false);

    const [pedido, setPedido] = useState({
        "idCliente": null,
        "fecha": null,
        "semana": param.semana,
        "dia": [{
            "dia": 1,
            "pequeño": "0",
            "mediano": "0",
            "grande": "0",
            "lechal": "0",
            "cabrito": "0",
            "sangre": "0",
        },
        {
            "dia": 2,
            "pequeño": "0",
            "mediano": "0",
            "grande": "0",
            "lechal": "0",
            "cabrito": "0",
            "sangre": "0",
        },
        {
            "dia": 3,
            "pequeño": "0",
            "mediano": "0",
            "grande": "0",
            "lechal": "0",
            "cabrito": "0",
            "sangre": "0",
        }]
    });
    const CssTextField = styled(TextField)({
        '& label.Mui-focused': {
            color: '#72bd9a',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#B2BAC2',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E0E3E7',
            },
            '&:hover fieldset': {
                borderColor: '#B2BAC2',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#72bd9a',
            },
        }
    });



    const mostrarForm = (cliente) => {
        setClienteSeleccionado(clienteSeleccionado === cliente ? null : cliente);
    }
    const cambiarColorFondo = (index) => {
        setDivSeleccionado(index);
    };

    // const mostrarDatos = (idCliente) => {


    // }
    const firstLoad = () => {
        axios.get('https://' + wsUrl + '/fuertes/WS/verDetallesPedido.php?semana=' + param.semana)
            .then(function (response) {
                // console.log("Pedidos: ", response.data);

                if (Array.isArray(response.data)) {
                    setPedidos(response.data);
                    setLoaded(true);
                } else {
                    setPedido([]);
                }
                // console.log("pedidos: ", pedidos);
            })
            .catch(function (error) {
                setPedidos(pedido);
                console.error('Error en la solicitud:', error);
            })
            .finally(function () {
            })
    }



    const handleChange = (event) => {




        let numDia = event.target.name.charAt(0);
        switch (event.target.name) {
            // case 'nSemana':
            //     console.log(event.target.value);
            //     setSemana(event.target.value);
            //     break;
            //--------------DIA-1-
            case '0pequeño':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], pequeño: event.target.value } } });
                break;
            case '0mediano':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], mediano: event.target.value } } });
                break;
            case '0grande':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], grande: event.target.value } } });
                break;
            case '0lechal':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], lechal: event.target.value } } });
                break;
            case '0cabrito':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], cabrito: event.target.value } } });
                break;
            case '0sangre':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], sangre: event.target.value } } });
                break;
            //--------------DIA-2-
            case '1pequeño':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], pequeño: event.target.value } } });
                break;
            case '1mediano':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], mediano: event.target.value } } });
                break;
            case '1grande':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], grande: event.target.value } } });
                break;
            case '1lechal':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], lechal: event.target.value } } });
                break;
            case '1cabrito':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], cabrito: event.target.value } } });
                break;
            case '1sangre':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], sangre: event.target.value } } });
                break;
            //--------------DIA-3-
            case '2pequeño':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], pequeño: event.target.value } } });
                break;
            case '2mediano':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], mediano: event.target.value } } });
                break;
            case '2grande':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], grande: event.target.value } } });
                break;
            case '2lechal':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], lechal: event.target.value } } });
                break;
            case '2cabrito':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], cabrito: event.target.value } } });
                break;
            case '2sangre':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], sangre: event.target.value } } });
                break;
            case 'dia':
                setDia(event.target.value);
                console.log("Dia: " + event.target.value);
                break;
            default:
                break;
        }
    }

    const getEnviado = (pedidoDetalles, dia) => {
        let rdo = false;
        switch (dia) {
            case 1:
                if (pedidoDetalles.dias[0].envioManual == 1) {
                    rdo = true;
                    break;
                } else {
                    break;
                }
            case 2:
                if (pedidoDetalles.dias[1].envioManual == 1) {
                    rdo = true;
                    break;
                } else {
                    break;
                }
            case 3:
                if (pedidoDetalles.dias[2].envioManual == 1) {
                    rdo = true;
                    break;
                } else {
                    break;
                }
            default:
                break;
        }

        return rdo;
    }

    const enviarPedido = () => {
        axios.put('https://' + wsUrl + '/fuertes/WS/crearPedido.php', pedido)
            .then(function (response) {
                // console.log(pedido);
                if (response.data.success) {
                    // console.log(response.data);
                    setColorFondo(true);
                    // cambiarColorFondo(index);
                } else {
                    // console.log(response.data);
                }

            })
            .catch(function (error) {
                console.error('Error al enviar la solicitud:', error);
            });
    }


    useEffect(() => {
        firstLoad();
    }, [])

    return (
        <>
            <div className='flex-vertical' style={{ padding: '1em 1em' }}>
                <Cabecera></Cabecera>

                <div className='flex-vertical' style={{ marginTop: '6em', marginBottom: '7em', backgroundColor: '#FFF', borderRadius: '15px', padding: '1em 1em', minWidth: '0em !important', boxShadow: 'rgba(0, 0, 0, 0.15) 4px 5px 20px 0px', textAlign: 'center' }}>
                    <div className='pedido' style={{ textAlign: 'center' }}>
                        <Button variant="text" color='secondary' style={{ fontSize: '18px', fontWeight: 'bold', color: '#72bd9a' }} onClick={() => Navigate("/Historial")}><ReplyIcon className='icono' /> Atras</Button>
                        <h3>Editar pedido semana: {param.semana}</h3>
                        <Button variant="text" color='secondary' style={{ fontSize: '18px', fontWeight: 'bold', color: '#72bd9a' }} onClick={() => Navigate("/")}><HomeIcon className='icono' /> Inicio</Button>
                    </div>
                    <div className='flex-horizontal' style={{ marginBottom: '10px'}}>
                        <strong style={{marginRight: '0.5em'}}>Dia:  </strong>
                        <Select name='dia' labelId="demo-simple-select-label" id="demo-simple-select" value={dia} onChange={handleChange}>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                        </Select>
                    </div>

                    {(loaded === true) && (
                        <div className='flex-vertical' style={{ minWidth: '100%', maxWidth: '100%', gap: '5px' }}>
                            {pedidos.map((pedidoDetalles, index) => (
                                <div className={`flex-vertical 
                                    ${getEnviado(pedidoDetalles, dia) ? 'enviado' : 'no-enviado'}`}
                                    style={{ minWidth: '100%', maxWidth: '100%' }} key={index}>
                                    <EditarForm pedidoDetalles={pedidoDetalles} index={index} semana={param.semana} clienteSeleccionado={clienteSeleccionado} dia={dia}></EditarForm>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <PiePagina></PiePagina>
            </div>
        </>



    )
}
export default EditarPedido;