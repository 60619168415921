import React, { useState } from 'react';
import PiePagina from '../components/PiePagina';
import Cabecera from '../components/Cabecera';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { alpha, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

export const Login = ({ username, setUsername, password, setPassword }) => {
    //global
    const navigate = useNavigate();

    //util
    const wsUrl = "www.jcinformatics.com";
    const [mostrarPaswd, setMostrarPaswd] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    //value
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLogin = async () => {
        // console.log(username);
        // console.log(password);
        try {
            const response = await axios.post('https://' + wsUrl + '/fuertes/WS/login.php'
                , {
                    username: username,
                    password: password
                });

            if (response.data.success) {
                // Guarda el token en una cookie con una fecha de expiración
                const tokenExpiryDate = new Date();
                tokenExpiryDate.setTime(tokenExpiryDate.getTime() + (12 * 60 * 60 * 1000)); // 12 horas
                document.cookie = `PHPSESSID=${response.data.token}; path=/; expires=${tokenExpiryDate.toUTCString()};`;

                // Guarda otros datos en el almacenamiento local
                localStorage.setItem('username', response.data.nombre);
                localStorage.setItem('mail', response.data.mail);
                localStorage.setItem('DesRepresentante', response.data.DesRepresentante);
                setPassword('');
                setUsername('');

                Swal.fire({
                    icon: "success",
                    title: `Bienvenido/a \n${username}`,
                    showConfirmButton: false,
                    timer: 1500
                });
                navigate('/');
            } else {
                // console.log(response.data)
                Swal.fire({
                    icon: "error",
                    title: "Error de inicio de sesión: \n" + response.data.message,
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        } catch (error) {
            console.error('Error de red:', error.message);
        }
    };

    // const CssTextField = styled(TextField)({
    //     '& label.Mui-focused': {
    //         color: '#72bd9a',
    //     },
    //     '& .MuiOutlinedInput-root': {
    //         '&.Mui-focused fieldset': {
    //             borderColor: '#72bd9a',
    //         },
    //     }
    // });

    const handleChange = (event) => {
        switch (event.target.name) {
            case 'username':
                setUsername(event.target.value);
                break;

            case 'password':
                setPassword(event.target.value);
                break;

            default:
                break;
        }

    }

    return (
        <div className='main'>
            <Cabecera></Cabecera>
            <div className='flex-vertical' style={{ marginTop: '11em', backgroundColor: '#FFF', borderRadius: '15px', padding: '2em 3.5em', minWidth: '0em !important', boxShadow: 'rgba(0, 0, 0, 0.15) 4px 5px 20px 0px' }}>
                <div className='flex-vertical' style={{ gap: '1em' }}>
                    <h2>Inicia sesión</h2>
                    <TextField
                        name='username'
                        value={username}
                        id="standard-basic"
                        label="Nombre usuario"
                        variant="outlined"
                        // color="secondary"
                        onChange={handleChange}
                        style={{ width: '12em' }}
                    />
                    <FormControl sx={{ width: '12em' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end" style={{ maxWidth: '1em', marginRight: '0.5em' }}>
                                    <IconButton

                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        style={{ maxWidth: '1em', minWidth: '1em' }}

                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Contraseña"
                            // Agrega este bloque onChange para actualizar el estado de la contraseña
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </FormControl>
                    <Button variant="contained" color='secondary' style={{ backgroundColor: '#72bd9a', marginTop: '1em' }} onClick={handleLogin}>Iniciar sesion</Button>

                </div>
            </div>

            <PiePagina></PiePagina>
        </div>

    );
}

export default Login;
