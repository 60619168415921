import React, { useState } from 'react';
import Cabecera from '../components/Cabecera';
import PiePagina from '../components/PiePagina';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { getCookie } from '../utils/cookies';




const MainMenu = () => {

  const Navigate = useNavigate();

  const cerrarSesion = async () => {
    const token = getCookie('PHPSESSID');


    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('code');
    localStorage.removeItem('mail');
    localStorage.removeItem('DesRepresentante');


    Navigate("/login");

    try {
      const response = await axios.post('https://jcinformatics.com/fuertes/WS/Logout.php', {
        'token': token,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

    } catch (error) {
      console.error('Error al invalidar el token', error);
    }
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  return (
    <div className='main'>
      <Cabecera></Cabecera>
      <div className='flex-vertical' style={{ marginTop: '11em', backgroundColor: '#FFF', borderRadius: '15px', padding: '2em 3.5em', minWidth: '0em !important', boxShadow: 'rgba(0, 0, 0, 0.15) 4px 5px 20px 0px' }}>
        <h2>Menu Principal</h2>

        <div className='flex-vertical' style={{ gap: '2em', padding: '1em 0' }}>
          <Button style={{ minWidth: '10em', padding: '1em 2.75em', backgroundColor: '#72bd9a' }} variant="contained" onClick={() => Navigate("/MainForm")}>Crear pedidos</Button>
          <Button style={{ minWidth: '10em', padding: '1em', backgroundColor: '#72bd9a' }} variant="contained" onClick={() => Navigate("/Historial")}>Historial de pedidos</Button>
          <Button style={{ minWidth: '10em', padding: '1em 1.75em', backgroundColor: '#72bd9a' }} variant="contained" onClick={() => Navigate("/DetallesClientes")}>Detalles clientes</Button>
          <Button style={{ minWidth: '10em', padding: '1em 3.2em', backgroundColor: '#72bd9a' }} variant="contained" onClick={() => Navigate("/DetallesAPI")}>Detalles API</Button>
          <React.Fragment >
            <Button style={{ minWidth: '10em', padding: '1em 2.75em', backgroundColor: '#72bd9a' }} variant="contained" onClick={handleClickOpen}>Cerrar sesión</Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className='blur'
            >
              <DialogTitle id="alert-dialog-title">
                {"Seguro que desea cerrar sesión?"}
              </DialogTitle>
              <DialogActions style={{ justifyContent: 'center', gap: '2em', marginBottom: '1em' }}>
                <Button variant="contained" color='secondary' style={{ backgroundColor: '#72bd9a' }} onClick={handleClose}>Cancelar</Button>
                <Button variant="contained" color='secondary' style={{ backgroundColor: '#72bd9a' }} onClick={() => cerrarSesion()}>
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>


        </div>
      </div>
      {/* <PiePagina></PiePagina> */}
    </div>

  );
}

export default MainMenu;
