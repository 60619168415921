import React, { useState } from 'react';

const PiePagina = () => {


    return (
        <footer className='pie-pagina'>
            <h6> JC informatics</h6>
        </footer>

    );
}

export default PiePagina;
