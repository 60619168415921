import React, { useEffect, useState } from 'react';
import Cabecera from '../components/Cabecera';
import PiePagina from '../components/PiePagina';
import PedidoForm from '../components/PedidoForm';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { alpha, styled } from '@mui/material/styles';
// import { PedidoForm } from '../components/PedidoForm';
import Swal from 'sweetalert2';
import SvgIcon from '@mui/material/SvgIcon';
import ReplyIcon from '@mui/icons-material/Reply';
import HomeIcon from '@mui/icons-material/Home';

const MainForm = () => {
    const Navigate = useNavigate();


    const enviarPedido = (idCliente, index) => {
        // console.log(idCliente);
        setPedido({ ...pedido, idCliente: idCliente, fecha: fechaLunes });
        // console.log(pedido);
        axios.post('https://' + wsUrl + '/fuertes/WS/crearPedido.php', pedido)
            .then(function (response) {
                if (response.data.success) {
                    // console.log(response.data);
                    cambiarColorFondo(index);
                } else {
                    // console.log(response.data);
                }

            })
            .catch(function (error) {
                console.error('Error al enviar la solicitud:', error);
            });
    }

    // const CssTextField = styled(TextField)({
    //     '& label.Mui-focused': {
    //         color: '#72bd9a',
    //     },
    //     '& .MuiInput-underline:after': {
    //         borderBottomColor: '#B2BAC2',
    //     },
    //     '& .MuiOutlinedInput-root': {
    //         '& fieldset': {
    //             borderColor: '#E0E3E7',
    //         },
    //         '&:hover fieldset': {
    //             borderColor: '#B2BAC2',
    //         },
    //         '&.Mui-focused fieldset': {
    //             borderColor: '#72bd9a',
    //         },
    //     }
    // });

    const wsUrl = "www.jcinformatics.com";
    const [fechaLunes, setFechaLunes] = useState('');
    const [clientes, setClientes] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
    const [divSeleccionado, setDivSeleccionado] = useState(null);
    const [semana, setSemana] = useState("");
    const [tempSemana, setTempSemana] = useState("");
    const [mostrarLista, setMostrarLista] = useState(false);
    const [envioManual, setEnvioManual] = useState([]);
    const [pedidos, setPedidos] = useState([]);


    // Función para modificar el background-color del div según su índice
    const cambiarColorFondo = (index) => {
        setDivSeleccionado(index);
    };


    const mostrarForm = (cliente) => {
        setClienteSeleccionado(clienteSeleccionado === cliente ? null : cliente);
    }

    const confirmarSemana = (semana) => {
        setSemana(semana);
        // console.log(semana);
        // //inicializar semana
        if ((semana != "") && (parseInt(semana) > 0)) {
            axios.get('https://' + wsUrl + '/fuertes/WS/inicializarSemana.php?semana=' + semana + '&fecha=' + fechaLunes)
                .then(function (response) {
                    axios.get('https://' + wsUrl + '/fuertes/WS/verDetallesPedido.php?semana=' + tempSemana)
                        .then(function (response) {
                            setPedidos(response.data);
                            // console.log("Pedidos: ", response.data);
                            if (Array.isArray(response.data)) {
                                // setEnvioManual(response.data);
                                // setLoaded(true);
                            } else {
                                // setPedido([]);
                            }
                            // console.log("pedidos: ", pedidos);
                        })
                        .catch(function (error) {
                            // setPedidos(pedido);
                            console.error('Error en la solicitud:', error);
                        })
                        .finally(function () {
                        })
                    // console.log(response);
                    setMostrarLista(true);
                })
                .catch(function (error) {
                    console.error('Error en la solicitud:', error);
                    setSemana("");
                    Swal.fire({
                        icon: "error",
                        title: "Introduzca numero de semana valido.",
                        showConfirmButton: false,
                        timer: 1000
                    });
                })
                .finally(function () {

                })
        } else {
            setSemana("");
            Swal.fire({
                icon: "error",
                title: "Introduzca numero de semana valido.",
                showConfirmButton: false,
                timer: 2000
            });
        }

    }


    useEffect(() => {
        firstLoad();

        // Función para obtener la fecha del lunes de la semana actual
        const obtenerLunesDeEstaSemana = () => {
            const today = new Date();
            const dayOfWeek = today.getDay();
            const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust when day is Sunday
            const lunesDeEstaSemana = new Date(today.setDate(diff));
            const formattedLunes = lunesDeEstaSemana.toISOString().split('T')[0];
            return formattedLunes;
        };

        // Obtener la fecha del lunes y establecerla en el estado
        const lunesDeEstaSemana = obtenerLunesDeEstaSemana();
        setFechaLunes(lunesDeEstaSemana);
    }, []);

    const firstLoad = () => {
        //cargar compras
        axios.get('https://' + wsUrl + '/fuertes/WS/clientes.php')
            .then(function (response) {
                setClientes(response.data);
                setLoaded(true);
            })
            .catch(function (error) {
                console.error('Error en la solicitud:', error);
            })
            .finally(function () {
            })
    }
    const handleChange = (event) => {
        let numDia = event.target.name.charAt(0);
        switch (event.target.name) {
            // case 'idCliente':
            //     setClienteSelect(event.target.name); // Actualiza el cliente seleccionado cuando cambia la selección
            //     setPedido({ ...pedido, idCliente: event.target.value});
            //     break;
            case 'nSemana':
                // console.log(event.target.value);
                setTempSemana(event.target.value);
                break;
            //--------------DIA-1-
            case '0pequeño':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], pequeño: event.target.value } } });
                break;
            case '0mediano':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], mediano: event.target.value } } });
                break;
            case '0grande':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], grande: event.target.value } } });
                break;
            case '0lechal':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], lechal: event.target.value } } });
                break;
            case '0cabrito':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], cabrito: event.target.value } } });
                break;
            case '0sangre':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], sangre: event.target.value } } });
                break;
            //--------------DIA-2-
            case '1pequeño':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], pequeño: event.target.value } } });
                break;
            case '1mediano':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], mediano: event.target.value } } });
                break;
            case '1grande':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], grande: event.target.value } } });
                break;
            case '1lechal':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], lechal: event.target.value } } });
                break;
            case '1cabrito':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], cabrito: event.target.value } } });
                break;
            case '1sangre':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], sangre: event.target.value } } });
                break;
            //--------------DIA-3-
            case '2pequeño':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], pequeño: event.target.value } } });
                break;
            case '2mediano':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], mediano: event.target.value } } });
                break;
            case '2grande':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], grande: event.target.value } } });
                break;
            case '2lechal':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], lechal: event.target.value } } });
                break;
            case '2cabrito':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], cabrito: event.target.value } } });
                break;
            case '2sangre':
                setPedido({ ...pedido, dia: { ...pedido.dia, [numDia]: { ...pedido.dia[numDia], sangre: event.target.value } } });
                break;
            default:
                break;
        }
    };

    const [pedido, setPedido] = useState({
        "idCliente": null,
        "fecha": null,
        "semana": null,
        "dia": [{
            "dia": 1,
            "pequeño": null,
            "mediano": null,
            "grande": null,
            "lechal": null,
            "cabrito": null,
            "sangre": null,
        },
        {
            "dia": 2,
            "pequeño": null,
            "mediano": null,
            "grande": null,
            "lechal": null,
            "cabrito": null,
            "sangre": null,
        },
        {
            "dia": 3,
            "pequeño": null,
            "mediano": null,
            "grande": null,
            "lechal": null,
            "cabrito": null,
            "sangre": null,
        }]
    });

    return (
        <div className='flex-vertical' style={{ padding: '1em 1em' }}>
            <Cabecera></Cabecera>
            <div className='flex-vertical' style={{ marginTop: '8em', marginBottom: '7em', backgroundColor: '#FFF', borderRadius: '15px', padding: '1em 1em', minWidth: '0em !important', boxShadow: 'rgba(0, 0, 0, 0.15) 4px 5px 20px 0px' }}>
                <div className='pedido'>
                    <Button variant="text" color='secondary' style={{ fontSize: '18px', fontWeight: 'bold', color: '#72bd9a' }} onClick={() => Navigate("/")}><ReplyIcon className='icono' /> Atras</Button>
                    <h2>Pedidos</h2>
                    <Button variant="text" color='secondary' style={{ fontSize: '18px', fontWeight: 'bold', color: '#72bd9a' }} onClick={() => Navigate("/")}><HomeIcon className='icono' /> Inicio</Button>
                </div>

                <div className='flex-horizontal' style={{ gap: '7em', marginTop: '-2.0em', marginBottom: '0em' }}>
                    <h5>Semana del {fechaLunes}</h5>
                </div>
                <div >
                    <div>
                        <div className='flex-vertical' style={{ marginTop: '-1em', alignItems: 'flex-start' }}>
                            <div className='flex-horizontal' style={{ gap: '1em', marginBottom: '2em', marginTop: '1em' }}>
                                <h3>Nº Semana: </h3>
                                <TextField value={tempSemana} name={"nSemana"} label="Nº Semana" id="custom-css-outlined-input" type="number" onChange={handleChange} InputLabelProps={{ shrink: true, }} disabled={semana === "" ? false : true} />
                            </div>
                            <div className='flex-horizontal' style={{ marginBottom: '0em', gap: '0.5em', marginTop: '-1em' }}>

                                <div className='flex-vertical'>
                                    {semana === "" ? <Button style={{ backgroundColor: '#72bd9a' }} color="secondary" variant="contained" onClick={() => confirmarSemana(tempSemana)} >Confirmar </Button>
                                        :
                                        pedidos.map((pedidoDetalles, index) => (
                                            <div className='flex-vertical' style={{ minWidth: '100%', maxWidth: '100%' }} key={index}>
                                                {/* {console.log(pedidoDetalles.dias[0].envioManual)} */}
                                                <PedidoForm pedidoDetalles={pedidoDetalles} index={index} semana={semana} clienteSeleccionado={clienteSeleccionado} enviado1={pedidoDetalles.dias[0].envioManual} enviado2={pedidoDetalles.dias[1].envioManual}></PedidoForm>
                                            </div>
                                        ))

                                        // clientes.map((cliente, index) => (
                                        //     (mostrarLista === true) && (
                                        //         <PedidoForm index={index} cliente={cliente} semana={semana} ></PedidoForm>
                                        //     )
                                        // ))
                                    }
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
            <PiePagina></PiePagina>
        </div>


    );
}

export default MainForm;