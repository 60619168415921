import React, { useEffect, useState } from 'react';
import Cabecera from '../components/Cabecera';
import PiePagina from '../components/PiePagina';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReplyIcon from '@mui/icons-material/Reply';
import HomeIcon from '@mui/icons-material/Home';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';



export const Historial = () => {

  const Navigate = useNavigate();

  const wsUrl = "www.jcinformatics.com";

  const [pedido, setPedido] = useState({});
  const [loaded, setLoaded] = useState(false);

  const eliminarSemana = (semana) => {
    // console.log(semana);
    axios.delete('https://' + wsUrl + '/fuertes/WS/eliminarSemana.php', { data: { 'semana': semana } })
      .then(function (response) {
        let temp = [...pedido];
        temp = pedido.filter((pedid) => pedid.semana !== semana);
        setPedido(temp);
      })
      .catch(function (error) {
        console.error('Error en la solicitud:', error);
      })
      .finally(function () {
      })
  }


  useEffect(() => {
    firstLoad();
  }, [])

  const firstLoad = () => {

    //cargar pedido
    axios.get('https://' + wsUrl + '/fuertes/WS/historial.php')
      .then(function (response) {
        // console.log(response.data);
        setPedido(response.data);
        setLoaded(true);
      })
      .catch(function (error) {
        console.error('Error en la solicitud:', error);
      })
      .finally(function () {
      })
  }
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <div className='main'>
      <Cabecera></Cabecera>
      <div className='flex-vertical' style={{ marginTop: '6em', marginBottom: '7em', backgroundColor: '#FFF', borderRadius: '15px', padding: '1em 1em', minWidth: '0em !important', boxShadow: 'rgba(0, 0, 0, 0.15) 4px 5px 20px 0px' }}>
        <div className='pedido' style={{ textAlign: 'center' }}>
          <Button variant="text" color='secondary' style={{ fontSize: '18px', fontWeight: 'bold', color: '#72bd9a' }} onClick={() => Navigate("/")}><ReplyIcon className='icono' /> Atras</Button>
          <h3>Historial de pedidos</h3>
          <Button variant="text" color='secondary' style={{ fontSize: '18px', fontWeight: 'bold', color: '#72bd9a' }} onClick={() => Navigate("/")}><HomeIcon className='icono' /> Inicio</Button>
        </div>


        {(loaded === true) && (
          <>
            {pedido.map((pedido, index) => (
              <div className='flex-vertical' s>
                <div className='flex-horizontal' style={{ gap: '1em' }}>
                  <h4>Semana {pedido.semana}</h4>
                  <Button variant="contained" color='secondary' style={{ backgroundColor: '#72bd9a' }} onClick={() => Navigate("/DetallesHistorial/" + pedido.semana)}>Ver</Button>
                  <Button variant="contained" color='secondary' style={{ backgroundColor: '#72bd9a' }} onClick={() => Navigate("/EditarPedido/" + pedido.semana)}>Editar</Button>
                  {/* <React.Fragment>
                    <Button variant="contained" color='secondary' style={{ backgroundColor: '#72bd9a' }} onClick={handleClickOpen} >Eliminar</Button>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      className='blur'
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Seguro que desea eliminar la semana?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Si la elimina no podra recuperar los datos.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions style={{ justifyContent: 'center', gap: '2em', marginBottom: '1em' }}>
                        <Button variant="contained" color='secondary' style={{ backgroundColor: '#72bd9a' }} onClick={handleClose}>Cancelar</Button>
                        <Button variant="contained" color='secondary' style={{ backgroundColor: '#72bd9a' }} onClick={() => { eliminarSemana(pedido.semana); handleClose(); }}>
                          Aceptar
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment> */}
                </div>
              </div>
            ))}



          </>
        )}
      </div>
      <PiePagina></PiePagina>
    </div>
  )
}

export default Historial;
